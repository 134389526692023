import { useEffect, useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useParams
} from "react-router-dom";
import { PatientPage } from './component/PatientPage';
import firebase from 'firebase';
import { ConfirmPatientPage } from './component/ConfirmPatientPage';
import { EnterForm } from './component/EnterForm';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import thLocale from "date-fns/locale/th";
import { SaveSuccess } from './component/SaveSuccess';


const config = {
  apiKey: "AIzaSyCMhzz0WNgTb86xJeBDnjocDHwyi6KbhCA",
  authDomain: "hm-form.firebaseapp.com",
  projectId: "hm-form",
  storageBucket: "hm-form.appspot.com",
  messagingSenderId: "1029438876151",
  appId: "1:1029438876151:web:8583d0fd106d24be6b66f2",
  measurementId: "G-L9TJR0T636"
};

type SlugParam = {
  slug: string;
};

export type HosData = {
  url: string;
  name: string;
};

export type PatientInfo = {
  name: string;
  hn: string;
};


let selectPatient: PatientInfo;

function HomePage() {
  let { slug } = useParams<SlugParam>();
  const [result, setResult] = useState<HosData>();
  const [list, setList] = useState<HosData[]>();

  const [page, setPage] = useState(1);

  const [collectionNameList, setCollectionNameList] = useState<any>();


  function onChange(data: { info: PatientInfo, hos: HosData }) {
    selectPatient = data.info;
    setResult(data.hos);
    setPage(2);
  }

  //load data 
  useEffect(() => {
    let active = true
    if (slug)
      load(slug);
    else
      loadAll();

    async function load(hosname: string) {
      setResult(undefined) // this is optional

      const db = firebase.firestore();
      const res = await db.collection("hos").doc(hosname).get();
      if (!active) { return }
      if (res.exists)
        setResult(res.data() as HosData)
      else {
        loadAll();
      }
    }

    async function loadAll() {
      setResult(undefined) // this is optional

      const db = firebase.firestore();
      const res = await db.collection("hos").get();

      if (!active) { return }
      const list = res.docs.map((item) => item.data() as HosData)
      setList(list)

      var obj: any = [];
      res.forEach(function (doc) {
        obj[doc.data().name] = doc.id;
      });
      setCollectionNameList(obj);      
    }

  }, [slug]);
  console.log("result", result);
  return (
    <>
      {(page === 1) && <>
        {((result || list)) && <PatientPage data={result} list={list} onChange={onChange} />}
        {!(result || list) && "waiting.."}
      </>}
      {(page === 2) && <>
        <ConfirmPatientPage hos={result!} data={selectPatient} client={(slug)?slug:(collectionNameList[result!.name]) } onBack={() => setPage(1)} onNext={() => setPage(3)} />

      </>}
      {(page === 3) && <>
        <EnterForm hos={result!} data={selectPatient} client={(slug)?slug:(collectionNameList[result!.name]) } onBack={() => setPage(1)} onNext={() => setPage(4)} />

      </>}
      {(page === 4) && <>
        <SaveSuccess onNext={() => setPage(1)} />
      </>}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
}));

function App() {
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
  }
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={thLocale}>
      <div className={classes.root}>
        <div className="flex flex-col w-full">

          <div className="h-12 p-2"><img className="object-contain h-10" alt="HM_logo" src="HM_logo.svg" /></div>
          <div className="text-center bg-gray-200 text-blue-600 font-bold text-xl p-6">
            <div className="">ระบบบันทึก</div>
            <div className="">ติดตามอาการสำหรับผู้ป่วย</div>
          </div>
          <Router>
            <Switch>
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/:slug">
                <HomePage />
              </Route>
            </Switch>
          </Router>

        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
}

export default App;

