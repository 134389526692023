
import { HosData, PatientInfo } from "../App";



export function ConfirmPatientPage(props: { data: PatientInfo, hos: HosData, client: string, onBack: () => void, onNext: () => void }) {

console.log(props.data);

    return (
        <div className="flex flex-row w-full  justify-center">
            <div className="flex flex-col w-96 text-center ">
                <div className="flex flex-col rounded-xl border-4 border-blue-400 px-2 py-6 mt-6">
                    <div className="text-blue-600 font-bold text-3xl underline">{props.data.name}</div>
                    <div className="text-3xl font-bold underline pt-6">{props.data.hn}</div>
                    <div className=" text-3xl font-bold underline">{props.hos.name}</div>                    
                </div>

                <div className="flex flex-row justify-between pt-9">
                    <button
                        onClick={props.onBack}
                        type="button"
                        className="inline-flex items-center space-x-1 px-5 py-2 
                        rounded-xl overflow-hidden focus:outline-none focus:shadow-outline shadow-lg transition ease-out duration-200 bg-white  text-blue-500 border-2 border-blue-500 text-sm lg:text-base">
                        ยกเลิก
                    </button>
                    <button
                        onClick={props.onNext}
                        type="button"
                        className="inline-flex items-center space-x-1 px-5 py-2 
                        rounded-xl overflow-hidden focus:outline-none focus:shadow-outline shadow-lg transition ease-out duration-200 bg-blue-400 hover:bg-blue-500 text-white text-sm lg:text-base">
                        เริ่มกรอกสัญญานชีพ
                    </button>


                </div>



            </div>

        </div>
    );
}


