import { useState } from "react";
import { HosData, PatientInfo } from "../App";
import axios from 'axios'
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";


export function PatientPage(props: { data: HosData | undefined, list: HosData[] | undefined, onChange: (data: { info: PatientInfo, hos: HosData }) => void }) {

    const [search, setSearch] = useState(false);
    const [state, setState] = useState('');
    const [error, setError] = useState<undefined | string>();
    const [hos, setHos] = useState(props.data);
    const onButtonClick = () => {
        if (!hos) return;
        setSearch(true);
        // `current` points to the mounted text input element
        console.log("onButtonClick", state);
        const data = {
            hn: state
        }
        axios.post(hos!.url + "getPatient", data).then((repos) => {

            console.log(repos);
            if (repos.data.code !== 200) {
                console.error(error);
                setError("ไม่พบผู้ป่วย");
                setSearch(false);
            } else {
                setSearch(false);
                props.onChange({ info: { name: `${repos.data.value.title} ${repos.data.value.fname} ${repos.data.value.lname}`, hn: repos.data.value.hn }, hos: hos });

            }
        }).catch((error) => {

            console.error(error);
            setError("ไม่พบผู้ป่วย");
            setSearch(false);
        });

    };
    const menus = props.list?.map((item, index) => <MenuItem key={index} value={item.name}>{item.name}</MenuItem>)

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setHos(props.list?.find(t => t.name === (event.target.value as string)));
    };

    return (
        <div className="flex flex-row w-full  justify-center">
            <div className="flex flex-col w-96 text-center ">
                <div className="text-blue-800 font-bold text-3xl pt-9 text-l underline">หมายเลขผู้ป่วย</div>
                {hos !== undefined && <div className="pb-6 italic text-gray-500">{hos.name}</div>}
                <div className="shadow-lg p-2">
                    <input className="w-full p-2 border-b-2 border-black-600 focus:border-none"
                        type="text"
                        placeholder="HNxxxx"
                        value={state}
                        onChange={(value) => setState(value.target.value)} />

                </div>
                <div className="h-4"></div>
                {menus && <div className="shadow-lg p-2">
                    <FormControl className="w-full">
                        <InputLabel id="demo-simple-select-label">โรงพยาบาล</InputLabel>
                        <Select

                            value={hos?.name ?? ''}
                            onChange={handleChange}
                        >
                            {menus}
                        </Select>
                    </FormControl>

                </div>}
                <div className="pt-9 text-right">

                    <button
                        onClick={onButtonClick}
                        disabled={search}
                        type="button"
                        className={`${search ? 'cursor-wait' : ''} inline-flex items-center space-x-1 px-5 py-2 
                        rounded-l overflow-hidden focus:outline-none focus:shadow-outline shadow-lg transition ease-out duration-200 bg-blue-400 hover:bg-blue-500 text-white text-sm lg:text-base`}
                    >
                        <span>{search ? 'Searching...' : 'Search'}</span>
                        {search && <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                    <stop stopColor="#fff" stopOpacity="0" offset="0%" />
                                    <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
                                    <stop stopColor="#fff" offset="100%" />
                                </linearGradient>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)">
                                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 18 18"
                                            to="360 18 18"
                                            dur="0.9s"
                                            repeatCount="indefinite" />
                                    </path>
                                    <circle fill="#fff" cx="36" cy="18" r="1">
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 18 18"
                                            to="360 18 18"
                                            dur="0.9s"
                                            repeatCount="indefinite" />
                                    </circle>
                                </g>
                            </g>
                        </svg>}

                        {!search && <svg
                            className="h-5 w-autos"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>}
                    </button>
                </div>
                {error && <div className="mt-4 text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Holy smokes!</strong>
                    <span className="pl-2 block sm:inline">Something seriously bad happened.</span>
                    <button type="button" onClick={() => setError(undefined)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </button>
                </div>}
            </div>

        </div >
    );
}


