import React, { useState } from "react";
import { HosData, PatientInfo } from "../App";
import { FormControl, FormControlLabel, FormLabel, Input, InputLabel, Radio, RadioGroup, Snackbar, Switch } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from 'axios'
import { DateTimePicker } from "@material-ui/pickers";

const details = ["Low", "Medium", "High"]



export function EnterForm(props: { data: PatientInfo, hos: HosData, client: string, onBack: () => void, onNext: () => void }) {

    const [busy, setBusy] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<undefined | string>();
    const [room, setRoom] = useState<undefined | string>('');

    const [ans1, setAns1] = useState<undefined | number>(undefined);
    const [ans2, setAns2] = useState<undefined | number>(undefined);
    const [ans3, setAns3] = useState<undefined | number>(undefined);
    const [ans4, setAns4] = useState<undefined | number>(undefined);
    const [ans5, setAns5] = useState<undefined | number>(undefined);
    const [ans6, setAns6] = useState<undefined | number>(undefined);
    const [ans7, setAns7] = useState<undefined | number>(undefined);
    const [ans8, setAns8] = useState<undefined | number>(undefined);
    const [ans9, setAns9] = useState<undefined | number>(undefined);
    const [ans10, setAns10] = useState<undefined | number>(undefined);
    const [ans11, setAns11] = useState<undefined | number>(undefined);
    const [ans12, setAns12] = useState<undefined | number>(undefined);

    const [time, setTime] = React.useState(false);
    const [timetxt, setTimeTxt] = useState<Date | null>(new Date());

    const [otherAns, setOtherAns] = useState('');

    const [temp, setTemp] = useState<undefined | string>();
    const [bpMax, setBPMax] = useState<undefined | string>();
    const [bpMin, setBPMin] = useState<undefined | string>();
    const [pr, setPr] = useState<undefined | string>();
    const [rr, setRr] = useState<undefined | string>();
    const [o2Satuation, setO2Satuation] = useState<undefined | string>();
    const [roomOther, setRoomOther] = useState<undefined | string>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRoom((event.target as HTMLInputElement).value);
    };

    function convertRadio(value: undefined | number): null | string {
        if (value === undefined)
            return null;
        return value ? "Y" : "N";
    }


    function convertRadioDetail(value: undefined | number): null | string {
        if (value === undefined)
            return null;
        return details[value];
    }

    const onButtonClick = () => {
        setBusy(true);

        const data = {
            "client": 'https://ipdpaperless.com/' + props.client,
            "hn": props.data.hn,
            "vs": {
                "TempC": temp,
                "PR": pr,
                "BPMax": bpMax,
                "BPMin": bpMin,
                "RR": rr,
                "O2Saturation": o2Satuation,
                "other": room === "other" ? roomOther : room,
                "activeDate": time ? timetxt?.toISOString() : (new Date()).toISOString()
            },
            "assessingSymptoms": {
                "cough": convertRadio(ans1),
                "sputum": convertRadio(ans2),
                "soreThroat": convertRadio(ans3),
                "mucous": convertRadio(ans4),
                "stomachAche": convertRadio(ans5),
                "liquidStool": convertRadio(ans6),
                "musclePain": convertRadio(ans7),
                "conjunctivitis": convertRadio(ans8),
                "rash": convertRadio(ans9),
                "shortOfBreath": convertRadio(ans10),
                "shortOfBreathDetail1": convertRadioDetail(ans11),
                "shortOfBreathDetail2": convertRadioDetail(ans12),
                "other": otherAns
            }
        }
        console.log(data);


        axios.post(props.hos.url + "saveIpdTemp", data).then((repos) => {

            console.log(repos);
            setBusy(false);
            setSuccess(true);
            props.onNext();
        }).catch((error) => {

            console.error(error);
            setError("ไม่พบผู้ป่วย");
            setBusy(false);
        });

    };

    return (
        <div className="flex flex-row w-full justify-center">
            <Snackbar
                open={success}
                autoHideDuration={5000}
                onClose={() => setSuccess(false)}
            >
                <div className="bg-green-500 text-white p-4 flex flex-row justify-between rounded shadow-xl w-56 ">
                    <div>Save Success!!</div>
                    <div>
                        <button onClick={() => setSuccess(false)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </Snackbar>
            <div className="flex flex-col w-full items-center">
                <div className="flex flex-col w-96 text-center ">
                    <div className="flex flex-col rounded-xl border-4 border-blue-400 px-2 py-6 mt-6">
                        <div className="text-blue-600 font-bold text-3xl underline">{props.data.name}</div>
                        <div className="text-3xl font-bold underline pt-6">{props.data.hn}</div>
                        <div className=" text-3xl font-bold underline">{props.hos.name}</div>
                    </div>
                </div>

                <div className="pt-4 pb-4 text-3xl font-bold underline">ติดตามอาการรายวัน</div>
                <div className="flex flex-col ">
                    <div className="shadow p-2 w-96">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t1">อุณหภูมิร่างกาย</InputLabel>
                            <Input
                                id="t1"
                                endAdornment={<InputAdornment position="end">°C</InputAdornment>}
                                value={temp}
                                onChange={(e) => setTemp(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>

                    </div>
                    <div className="h-4"></div>
                    <div className="flex flex-row shadow p-2 w-96 items-end">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">ความดันตัวบน</InputLabel>
                            <Input
                                id="t2"
                                endAdornment={<InputAdornment position="end">mm/Hg</InputAdornment>}
                                value={bpMax}
                                onChange={(e) => setBPMax(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                        <div className="px-2 text-3xl">/</div>
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">ความดันตัวล่าง</InputLabel>
                            <Input
                                id="t2"
                                endAdornment={<InputAdornment position="end">mm/Hg</InputAdornment>}
                                value={bpMin}
                                onChange={(e) => setBPMin(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">ชีพจร</InputLabel>
                            <Input
                                id="t2"
                                endAdornment={<InputAdornment position="end">/min</InputAdornment>}
                                value={pr}
                                onChange={(e) => setPr(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">อัตราการหายใจ</InputLabel>
                            <Input
                                id="t2"
                                endAdornment={<InputAdornment position="end">/min</InputAdornment>}
                                value={rr}
                                onChange={(e) => setRr(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">ออกซิเจนในเลือด</InputLabel>
                            <Input
                                id="t2"
                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                value={o2Satuation}
                                onChange={(e) => setO2Satuation(e.target.value)}
                                inputProps={{
                                    'aria-label': 'weight',
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96">
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Room O2</FormLabel>
                            <RadioGroup aria-label="room" name="room" value={room}
                                onChange={handleChange}>
                                <FormControlLabel value="Room Air" control={<Radio color="primary" />} label="Room air" />
                                <div className="flex flex-row items-center">
                                    <FormControlLabel value="other" control={<Radio color="primary" />} label="Other" />
                                    <div>
                                        <Input disabled={room !== "other"}
                                            value={roomOther}
                                            onChange={(e) => setRoomOther(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </RadioGroup>
                        </FormControl>
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96 flex flex-col">
                        <div className="flex flex-row items-center">
                            <Switch
                                checked={time}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTime(e.target.checked)}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                            <div>บันทึกแบบเลือกเวลา</div>
                        </div>
                        {time && <div className="p-4">
                            <DateTimePicker
                                value={timetxt}
                                onChange={setTimeTxt}
                                label="Pick Date Time"
                                showTodayButton
                                format="yyyy/MM/dd HH:mm"
                            />

                        </div>}
                    </div>
                    <div className="h-4"></div>
                    <div className="">อาการที่ต้องประเมิน</div>

                    <div className="grid grid-cols-3 gap-4 justify-items-start">
                        <div></div>
                        <div>ไม่มี</div>
                        <div className="px-4">มี</div>
                        <div className="p-2">ไอ</div>
                        <div>
                            <Radio
                                checked={ans1 === 0}
                                onClick={() => { setAns1(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans1 === 1}
                                onClick={() => { setAns1(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">มีเสมหะ</div>
                        <div>
                            <Radio
                                checked={ans2 === 0}
                                onClick={() => { setAns2(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans2 === 1}
                                onClick={() => { setAns2(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">เจ็บคอ</div>
                        <div>
                            <Radio
                                checked={ans3 === 0}
                                onClick={() => { setAns3(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans3 === 1}
                                onClick={() => { setAns3(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">มีน้ำมูก</div>
                        <div>
                            <Radio
                                checked={ans4 === 0}
                                onClick={() => { setAns4(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans4 === 1}
                                onClick={() => { setAns4(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">ปวดท้อง</div>
                        <div>
                            <Radio
                                checked={ans5 === 0}
                                onClick={() => { setAns5(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans5 === 1}
                                onClick={() => { setAns5(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">ถ่ายเหลว</div>
                        <div>
                            <Radio
                                checked={ans6 === 0}
                                onClick={() => { setAns6(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans6 === 1}
                                onClick={() => { setAns6(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">ปวดกล้ามเนื้อ</div>
                        <div>
                            <Radio
                                checked={ans7 === 0}
                                onClick={() => { setAns7(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans7 === 1}
                                onClick={() => { setAns7(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">ตาแดง</div>
                        <div>
                            <Radio
                                checked={ans8 === 0}
                                onClick={() => { setAns8(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans8 === 1}
                                onClick={() => { setAns8(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">ผื่นคัน</div>
                        <div>
                            <Radio
                                checked={ans9 === 0}
                                onClick={() => { setAns9(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans9 === 1}
                                onClick={() => { setAns9(1) }}
                                color="primary"
                            />
                        </div>
                        <div className="p-2">หายใจเหนื่อย</div>
                        <div>
                            <Radio
                                checked={ans10 === 0}
                                onClick={() => { setAns10(0) }}
                                color="primary"
                            />
                        </div>
                        <div>
                            <Radio
                                checked={ans10 === 1}
                                onClick={() => { setAns10(1) }}
                                color="primary"
                            />
                        </div>
                        {ans10 === 1 && <>
                            <div className="p-2">หายใจลำบาก</div>
                            <div className="col-span-2 flex flex-col">
                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans11 === 0}
                                        onClick={() => { setAns11(0) }}
                                        color="primary"
                                    />
                                    <div>เล็กน้อย</div>
                                </div>

                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans11 === 1}
                                        onClick={() => { setAns11(1) }}
                                        color="primary"
                                    />
                                    <div>ปานกลาง</div>
                                </div>


                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans11 === 2}
                                        onClick={() => { setAns11(2) }}
                                        color="primary"
                                    />
                                    <div>มาก</div>
                                </div>

                            </div>
                            <div className="p-2">หายใจเหนื่อย</div>
                            <div className="col-span-2 flex flex-col">
                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans12 === 0}
                                        onClick={() => { setAns12(0) }}
                                        color="primary"
                                    />
                                    <div>เล็กน้อย</div>
                                </div>

                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans12 === 1}
                                        onClick={() => { setAns12(1) }}
                                        color="primary"
                                    />
                                    <div>ปานกลาง</div>
                                </div>


                                <div className="flex flex-row items-center">
                                    <Radio
                                        checked={ans12 === 2}
                                        onClick={() => { setAns12(2) }}
                                        color="primary"
                                    />
                                    <div>มาก</div>
                                </div>

                            </div>
                        </>
                        }
                    </div>
                    <div className="h-4"></div>
                    <div className="shadow p-2 w-96">
                        <FormControl className="w-full">
                            <InputLabel htmlFor="t2">อาการอื่นๆ</InputLabel>
                            <Input
                                id="t2"
                                value={otherAns}
                                onChange={(e) => setOtherAns(e.target.value)}
                            />
                        </FormControl>
                    </div>
                </div>
                {error && <div className="mt-4 text-left bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                    <strong className="font-bold">Holy smokes!</strong>
                    <span className="pl-2 block sm:inline">Something seriously bad happened.</span>
                    <button type="button" onClick={() => setError(undefined)} className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </button>
                </div>}
                <div className="flex flex-row justify-between py-9 w-96">
                    <button
                        onClick={props.onBack}
                        type="button"
                        className="inline-flex items-center space-x-1 px-5 py-2 
                        rounded-xl overflow-hidden focus:outline-none focus:shadow-outline shadow-lg transition ease-out duration-200 bg-white  text-blue-500 border-2 border-blue-500 text-sm lg:text-base">
                        ยกเลิก
                    </button>

                    <button
                        onClick={onButtonClick}
                        disabled={busy}
                        type="button"
                        className={`${busy ? 'cursor-wait' : ''} inline-flex items-center space-x-1 px-5 py-2 
                        rounded-xl overflow-hidden focus:outline-none focus:shadow-outline shadow-lg transition ease-out duration-200 bg-blue-600 hover:bg-blue-500 text-white text-sm lg:text-base`}
                    >
                        <span>{busy ? 'Saving...' : 'Submit'}</span>
                        {busy && <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                                <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
                                    <stop stopColor="#fff" stopOpacity="0" offset="0%" />
                                    <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
                                    <stop stopColor="#fff" offset="100%" />
                                </linearGradient>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                                <g transform="translate(1 1)">
                                    <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 18 18"
                                            to="360 18 18"
                                            dur="0.9s"
                                            repeatCount="indefinite" />
                                    </path>
                                    <circle fill="#fff" cx="36" cy="18" r="1">
                                        <animateTransform
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 18 18"
                                            to="360 18 18"
                                            dur="0.9s"
                                            repeatCount="indefinite" />
                                    </circle>
                                </g>
                            </g>
                        </svg>}


                    </button>


                </div>

            </div>
        </div >
    );
}


