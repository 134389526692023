export function SaveSuccess(props: { onNext: () => void }) {



    return (
        <div className="flex flex-row w-full  justify-center">
            <div className="flex flex-col w-96 text-center items-center ">

                <div className="py-14 text-4xl text-gray-500">
                    ติดตามอาการรายวัน
                </div>
                <div className="rounded-full h-36 w-36 flex items-center justify-center border-12 border-green-500">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full p-2 text-green-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M5 13l4 4L19 7" />
                    </svg>
                </div>
                <div className="py-12 text-4xl text-green-500">
                    Saved Successfully
                </div>

                <button
                    onClick={props.onNext}
                    type="button"
                    className="text-blue-600 hover:text-blue-500 font-bold text-xl">
                    Return to Home
                </button>



            </div>

        </div>
    );
}


